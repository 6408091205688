<template>
  <div class="rankWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="rankBar">
          <div class="title"><img :src="title" alt=""></div>
          <div class="rankMain">
            <div class="rankCon">
              <div class="rankMain">
                <div class="tagWrap">
                  <div v-for="(item,index) in tagList" class="tag" :class="{active:tagActive == index}" @click="changeTag(index)">
                    <template v-if="tagActive == index"><img :src="item.pic" alt=""></template>
                    <template v-else>{{item.name}}</template>
                  </div>
                </div>
                <div class="bubblePoint" v-if="tagActive == 1">
                  <template v-for="(item,index) in bubblePointList">
                    <div class="item" :class="{active:index == bubblePointActive}"@click="bubblePointChangeTag(index)"><span class="t">{{item.name}}</span><span class="b">{{item.des}}</span></div>
                  </template>
                </div>
                <div class="tableWrap">
                  <el-table
                    v-if="tabelShow"
                    style="width: 100%"
                    stripe
                    :data="tableData">
                    <el-table-column v-for="(item,index) in tableHead[tagActive]" :min-width="item.width">
                      <template slot="header" slot-scope="scope">
                        {{item.label}}
                      </template>
                      <template slot-scope="scope">
                        <template v-if="scope.$index < 3 && index == 0">
                          <img :src="require('@/assets/imgs/rank/'+scope.$index+'.png')" alt="">
                        </template>
                        <template v-else>{{scope.row[item.prop]}}</template>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="tipWrap">
                    <span>仅显示前30名榜单</span>
                    <span>{{updateDate}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="illustrateWrap">
              <div class="tit">规则说明：</div>
              <div class="cons">
                <p>1）排行榜数据每5分钟刷新一次。</p>
                <p>2）充值排行榜、特权公告排行榜、霸屏公告排行榜、超级公告排行榜、普通公告排行榜、赠花排行榜以及魅力排行榜每周一0点结算并重置数据。</p>
                <p>3）以上排行榜结算时登顶的玩家将被录入对应名人堂并在未来七天内发布公告时享有唯一专属身份标识（普通公告名人除外）。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import axios from "axios";
  import Cookies from "js-cookie";

  export default {
    name: 'record',
    components:{
      menuWX,
    },
    data(){
      return{
        tabelShow:true,
        title:require('@/assets/imgs/rank/title.png'),
        tagActive:this.$route.query.type?this.$route.query.type:0,
        bubblePointActive:0,
        bubblePointList:[
          {name:'特权公告',type:2,des:'（每周一重新统计）'},
          {name:'霸屏公告',type:3,des:'（每周一重新统计）'},
          {name:'超级公告',type:4,des:'（每周一重新统计）'},
          {name:'普通公告',type:5,des:'（每周一重新统计）'},
        ],
        tagList:[
          {name:'充值排行',type:1,pic:require('@/assets/imgs/rank/rank0.png')},
          {name:'公告排行',type:0,pic:require('@/assets/imgs/rank/rank1.png')},
          {name:'送花排行',type:6,pic:require('@/assets/imgs/rank/rank2.png')},
          {name:'魅力排行',type:7,pic:require('@/assets/imgs/rank/rank3.png')},
          {name:'泡点排行',type:8,pic:require('@/assets/imgs/rank/rank4.png')},
          {name:'VIP排行',type:9,pic:require('@/assets/imgs/rank/rank5.png')},
          {name:'家族排行',type:10,pic:require('@/assets/imgs/rank/rank6.png')},
        ],
        page:1,
        size:10,
        total:100,
        tableHead:[
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},],   // 充值
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},{prop:'field6',width:'100px',label:'发送次数'},], // 公告
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},{prop:'field6',width:'150px',label:'赠送鲜花数量'},], // 送花
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},{prop:'field6',width:'100px',label:'魅力值'},], // 送花
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},{prop:'field6',width:'100px',label:'泡点'},], // 泡点
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'角色昵称'}, {prop:'field3',width:'100px',label:'情侣昵称'}, {prop:'field4',width:'100px',label:'等级'}, {prop:'field5',width:'100px',label:'家族'},{prop:'field6',width:'150px',label:'累计获取积分'},], // VIP
          [{prop:'field1',width:'100px',label:'排名'}, {prop:'field2',width:'100px',label:'家族名称'}, {prop:'field3',width:'100px',label:'家族组长昵称'}, {prop:'field4',width:'100px',label:'家族人数'}, {prop:'field5',width:'100px',label:'综合指数'},], // 家族
        ],
        tableData:[],
        updateDate:'',
      }
    },
    created() {
      this.getRankData()
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
      getRankData(){
        let type = this.tagList[this.tagActive].type
        if(type == 0){
          type = this.bubblePointList[this.bubblePointActive].type
        }
        this.tabelShow = false
        axios({
          method: 'post',
          url: '/rank/getWebRankList',
          headers: {
            token: Cookies.get('wx_token'),
          },
          data: {
            type:type
          },
        }).then((res) => {
          this.tabelShow = true
          if(res.data.state == 200){
            this.updateDate = res.data.data.updateDate
            this.tableData = res.data.data.rankOneList
            this.$forceUpdate()
          }else{
            this.$message.error(res.data.message)
          }
        }).catch(err=>{
          this.$message.error(err.message)
        })
      },                // 获取游戏排行数据
      changeTag(index){
        this.tagActive = index
        this.getRankData()
      },
      bubblePointChangeTag(index){
        this.bubblePointActive = index
        this.getRankData()
      },
    }
  }
</script>

<style lang="less">
  .rankWrap{
    .rankMain{
      .el-table{
        border: 1px solid #A042FF;
        background: none;
        &::before{
          background: none;
        }
        .el-table__cell{
          border: none;
        }
        tr{
          border: none;
          background: none;
          .cell{
            text-align: center;
          }
          th{
            padding:0;
            height: 60px;
            line-height: 60px;
            background: #A042FF;
            .cell{
              color: #ffffff;
              font-size: 22px;
            }
          }
          td{
            padding:0;
            height: 50px;
            line-height: 50px;
            .cell{
              font-size: 14px;
            }
          }
          /*&:hover{
            .el-table__cell{
              background: none;
            }
            th.el-table__cell{
              background: #A042FF;
            }
          }*/

        }
        .el-table__row--striped{
          td.el-table__cell{
            background: rgba(160,66,255,0.1);
            border: none;
          }
        }
        tbody{
          tr{
            &:nth-child(1){
              td{
                background: #D5A8FC!important;
              }
            }
            &:nth-child(2){
              td{
                background: #DEBAFB!important;
              }
            }
            &:nth-child(3){
              td{
                background: #E6C8FB!important;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="less" scoped>
  .rankWrap{
    padding-bottom: 20px;
    .rankBar{
      padding-bottom: 20px;
      .title{
        transform: translateY(13px);
        text-align: center;
      }
      .rankMain{
        .rankCon{
          margin-bottom: 20px;
          padding:10px 13px;
          border-radius: 5px;;
          background: linear-gradient(to bottom, #FD8C81, #D459FB);
          box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
          .rankMain{
            padding:5px 17px;
            border-radius: 5px;
            background: linear-gradient(to bottom, #FFF6FA, #D9BBFF);
            .tagWrap{
              margin-bottom: 6px;
              display: flex;
              justify-content: space-between;
              .tag{
                display: inline-block;
                width: 115px;
                text-align: center;
                font-size: 20px;
                height: 42px;
                line-height: 42px;
                cursor: pointer;
              }
            }
            .bubblePoint{
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #A042FF;
              .item{
                padding:12px 0;
                width: 25%;
                text-align: center;
                color: #A042FF;
                height: 70px;
                box-sizing: border-box;
                line-height: 26px;
                cursor: pointer;
                border-right: 1px solid #A042FF;
                &:last-child{
                  border-right: none;
                }
                .t{
                  display: block;
                  font-size: 22px;
                }
                .b{
                  font-size: 18px;
                }
              }
              .active{
                color: #ffffff;
                background: #A042FF;
              }
            }
            .tipWrap{
              display: flex;
              padding: 8px 0 15px;
              justify-content: space-between;
              span{
                color: #FF0000;
              }
            }
          }
        }
        .illustrateWrap{
          padding:16px 30px 30px;
          background: #ffffff;
          box-shadow: 0px 2px 8px 0px #EED9FF, 0px 0px 4px 0px rgba(255,255,255,0.25);
          .tit{
            margin-bottom: 8px;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 23px;
          }
          .cons{
            padding:20px;
            background: rgba(160,66,255,0.1);
            border-radius: 10px;
            line-height: 30px;
            p{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
